.leftbar {
    width: 100%;
    /* height: 100%; */
    overflow-x: hidden;
    text-align: center;
}
.portfolio_style {
    text-align: center;
    font-family: fantasy;
}
.pa_list {
    font-size: 17px;
    color: white;
    padding: 0.5vw 2vw;
    text-align: right;
    font-family: initial;
}
.pa_list:hover {
    background: #343638;
}
.approve_deny {
    padding: 10px;
}