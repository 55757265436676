
.frag {
    border-top: rgba(0, 0, 0, 0.2) solid 1px;
}

footer.foo {
    border-top: rgba(0, 0, 0, 0.2) solid 1px;
    padding-top: 20px;
}

.loginWrapper {
    width: 25vw;
    background-color: #0E0E0E;
    margin: 130px auto 50px;
    /* padding: 40px; */
    border-radius: 10px;
    font-family: "Sui Generis";
}

.loginWrapper .h3 {
    font-size: 40px;
    color: #333;
    display: block;
    margin: 0;
}

.pos-center {
    /* display: flex!important; */
    justify-content: center!important;
  padding-bottom: 1.5rem !important;
}

.loginWrapper .form-group {
    margin-bottom: 0;
    margin-top: 15px;
    position: relative;
}

.loginWrapper .form-group>i {
    width: 30px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 26px;
    color: #444;
    font-size: 15px;
}

.loginWrapper .form-group .invalid-feedback {
    font-size: 13px;
    margin: 0;
}

.loginWrapper .form-group .form-control {
    height: 38px;
    padding: 0 8px;
    font-size: 14px;
}

.loginWrapper .form-group .form-control::-webkit-input-placeholder {
    color: #777;
}

.loginWrapper .form-group .form-control::-moz-placeholder {
    color: #777;
}

.loginWrapper .form-group .form-control:-ms-input-placeholder {
    color: #777;
}

.loginWrapper .form-group .form-control:-moz-placeholder {
    color: #777;
}

.loginWrapper .form-group label {
    margin: 0;
    font-size: 14px;
    color: #6E7684;
}

.loginWrapper .form-check {
    padding-left: 21px !important;
    font-size: 14px;
    font-weight: 400;
    margin-top: 7px;
}

.loginWrapper .conbtn {
    margin: 10px 0 0;
    width: 100%;
    border-radius: 5px !important;
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 600;
    cursor: pointer !important;
    font-size: 13px;
}

.loginWrapper .conbtn:hover {
    background-color: #444 !important;
}

.forgotPassword {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
    font-size: 13px;
    text-align: left;
    margin-top: 5px;
}

.forgotPassword a {
    color: white;
}

.socialLogin {
    display: flex;
    justify-content: center;
}

.socialSignIn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.socialSignIn>div {
    border-radius: 100%;
    height: 36px;
    width: 36px;
    border: #999 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background-color: transparent;
    font-size: 16px !important;
    cursor: pointer;
    transition: all 0.3s;
}
.socialLogin>div {
    border-radius: 100%;
    height: 36px;
    width: 36px;
    border: #999 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background-color: transparent;
    font-size: 16px !important;
    cursor: pointer;
    transition: all 0.3s;
}

.socialSignIn i {
    margin: 0 !important;
    font-size: 16px !important;
    padding: 0;
    text-align: center;
}
.socialLogin i {
    margin: 0 !important;
    font-size: 16px !important;
    padding: 0;
    text-align: center;
}

.socialSignIn .google {
    /* border-color: #DB4437; */
    border: none;
    color: #DB4437;
}
.socialLogin .google {
    /* border-color: #DB4437; */
    border: none;
    color: #DB4437;
}

.socialSignIn .google:hover {
    color: #fff;
}
.socialLogin .google:hover {
    color: #fff;
}

.socialSignIn .facebook {
    border: none;
    color: white;
}
.socialLogin .facebook {
    border: none;
    color: white;
}

.socialLogin .facebook:hover {
}

.socialLogin .twitter {
    border-color: #1DA1F2;
    color: #1DA1F2;
}

.socialLogin .twitter:hover {
    background-color: #1DA1F2;
    color: #fff;
}

.socialLogin .linkedin {
    border-color: #0e76a8;
    color: #0e76a8;
}

.socialLogin .linkedin:hover {
    background-color: #0e76a8;
    color: #fff;
}

.socialLogin .apple {
    border-color: #000;
    color: #000;
}

.socialLogin .apple:hover {
    background-color: #000;
    color: #fff;
}

.header__center {
    text-transform: uppercase;
}

.signUp {
    display: block;
    margin-top: 15px;
    font-size: 13px;
}

.signUp a {
    text-transform: uppercase;
    color: #F75500!important;
    font-weight: 600;
}

.twoInput {
    display: flex;
    justify-content: space-between;
}

.twoInput>.form-group {
    width: calc(50% - 5px);
}

.h3 {
    color: white !important;
    font-family: 'Sui Generis' !important;
}

.loginWrapper .css-1s2u09g-control {
    background-color: rgb(255, 255, 255) !important;
}
.loginWrapper .css-1pahdxg-control {
    background-color: white;
}

.loginWrapper .css-14el2xx-placeholder,
.loginWrapper .css-qc6sy-singleValue {
    font-size: 14px;
    color: #212529;
}

.loginWrapper .css-319lph-ValueContainer {
    /* padding-left: 30px; */
    /* background-color: white; */
}

.email-input>label {
    color: white;
}

.pwd-input>label {
    color: white;
}

.remember-me {
    color: white;
    font-size: 12px;
    text-align: justify;
    margin-left: 10px;
}

.signUp {
    color: white;
}

.signUp>a {
    color: white;
}