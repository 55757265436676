.custom-card {
  border: 2px solid #494949;
  border-radius: 3px;
}

.btn-warning {
  color: #fff !important;
  background-color: #f35301 !important;
  border-color: #ffffff !important;
  font-weight: 600;
}

.btn-warning:hover {
  color: #f35301 !important;
  background-color: #ffffff !important;
  border-color: #f35301 !important;
  transition: 0.5s;
}

.sponsoredList {
  width: 100%;
  display: flex;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  justify-content: space-around;
  height: 360px;
  /* overflow: auto; */
  /* margin-bottom: 173px; */
}

.sponsoredList .sponsoredListpanel {
  display: flex;
  flex-wrap: wrap;
  background: #0E0E0E;
  margin-top: 20px;
  /* border: rgba(0, 0, 0, 0.3) solid 1px;
  border-top: #080808 solid 5px; */
  padding: 10px 8px;
  font-size: 13px;
  justify-content: space-between;
}

.sponsoredList .sponsoredListpanel .sponsoredListBody {
  border: rgba(0, 0, 0, 0.3) solid 1px;
  box-sizing: border-box;
  width: 100%;
  background-color: #1D1F25;
  position: relative;
  padding: 15px 10px;
  height: auto;
  border-radius: 10px;
}

.sponsoredList .sponsoredListpanel:nth-child(2) .sponsoredListBody {
  width: calc(33.3333% - 10px);
  height: auto;
}

.sponsoredList .sponsoredListpanel {
  width: calc(20% - 10px);
}

.sponsoredList .sponsoredListpanel:nth-child(2) {
  width: calc(60% - 10px);
}

.sponsoredList .sponsoredListpanel>.h3 {
  min-width: 70%;
  font-size: 14px;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: flex-start;
}

.sponsoredList .sponsoredListpanel .sponsoredListBody .h5 {
  border-bottom: 1px solid white;
  font-size: 20px;
  text-align: center;
  left: 5px;
  top: 10px;
  background-color: rgba(255, 255, 255, 0);
  padding: 7px 0px;
  color: #fff;
  font-family: 'Sui Generis';
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .sponsoredList .sponsoredListpanel .sponsoredListBody .h5 {
    font-size: 11px;
  }
}

.sponsoredList .sponsoredListpanel .sponsoredListBody .sponsoredListView {
  margin-top: 0%;
}

.sponsoredList .sponsoredListpanel .sponsoredListBody .sponsoredListView .form-check {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.sponsored-head {
  text-align: center;
  font-family: 'Sui Generis';
  color: white;
}

.sponsoredList .sponsoredListpanel .sponsoredListBody .sponsoredListView .form-check input {
  margin: 0;
  margin-right: 5px;
}

.sponsoredList .sponsoredListpanel .sponsoredListBody .sponsoredListView .form-check label {
  height: 13px;
  display: flex;
  align-items: center;
  margin-top: -2px;
  font-size: 20px;
  color: white;
  font-family: 'poppins-semibold';
}

@media (max-width: 1024px) {
  .sponsoredList .sponsoredListpanel .sponsoredListBody .sponsoredListView .form-check label {
    font-size: 12px;
  }
}

.sponsoredList>.btn {
  margin-left: auto;
  margin-top: 15px;
}

.pitch-to-us {
  float: right;
  margin: 20px;
  margin-right: 7%;
  padding: 30px 50px!important;
  font-family: 'Sui Generis';
  border: none!important;
  border-radius: 0px!important;
}

.cardHeader {
  /* background-color: grey !important;
  color:white !important; */
  text-align: left;
  font-weight: bold;
  transition: 2s;
  width: 100%;
}

.expandIcon {
  float: right;
  margin: 3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  color: grey;
  background-color: white;
  /* color: ; */
}

.li-item a {
  cursor: pointer;
  color: black;
}

.pitchtous {
  width: 100%;
  height: 100%;
  max-height: 250px;
}

.everybuy {
  float: right;
  width: 10%;
  min-width: 120px;
  height: 40px !important;
  background-color: #f35301 !important;
}

.activateCard {
  transition: 2s;
}


@media screen and (max-width: 768px) {
  .sponsoredList .sponsoredListpanel {
    width: 100% !important;
    margin-top: 40px;
  }

  .sponsoredList {
    display: inline-block;
    height: auto;
  }
}

@media screen and (max-width: 630px) {
  .sponsoredListBody {
    width: 100% !important;
    margin-bottom: 50px !important;
    height: auto;
  }

}

@media (max-width: 425px) {
  .sponsoredList .sponsoredListpanel:nth-child(2) .sponsoredListBody {
    height: 150px !important;
  }

  .sponsoredList .sponsoredListpanel .sponsoredListBody {
    height: 150px !important;
  }
}