.addDomain{
    padding-top: 30px;
}
.hBfQvF table {
    width: 100%!important;
}
.addDomain .panelView{
    background-color: #1D1F25;
    border: rgba(0, 0, 0, 0.3) solid 1px;
    border-top: #000 solid 5px;
    padding: 2% 8%;
    font-size: 13px;
    border-radius: 25px;
    margin-top: 3%;
}
.addDomain .panelView .file-name{
    margin-bottom: 0;
    margin-top: 5px;
}
.addDomain .panelView .h3{
    min-width: 100%;
    font-size: 16px;
    margin: 0;
    height: 35px;
    display: flex;
    align-items: flex-start;
}
.uploadPanel {
    display: flex;
    flex-direction: column;
    border: rgba(0, 0, 0, 0.3) solid 1px;
}
.uploadPanel textarea{
    width: 100%;
    min-height: 160px;
    border: none;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 600;
    background: #1d1f25;
}
.uploadPanel .file_upload{
    border: none;
    background-color: #1d1f25;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    color: #8E8F92!important;
    font-family: 'Sui Generis';
}

.uploadControl{
    display: flex;
}
.uploadControl .btn{
    white-space: nowrap;
    padding: 0 20px;
    font-size: 13px;
    border-radius: 0;
    background-color: #1d1f2500;
    border: 1px solid #FF5301;
    border-radius: 15px;
}

.uploadPanel .file_upload i {
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #16a08400;
    color: #fff;
    border: 1px solid #FF5301;
    border-radius: 15px;
    margin-right: 20px;
}
.uploadPanel .file_upload:before{
    content: attr(data-title);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 16px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    visibility: hidden;
}
.uploadPanel .file_upload:after{
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.8);
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
}
.uploadPanel .file_upload:hover:before, .uploadPanel .file_upload:hover:after{
    visibility: visible;
}
.jKHlWS table {
    width: 100%!important;
}
.search-label {
    font-size: 20px;
}
::placeholder {
    color: #FFFFFF80;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
  }
@media screen and (max-width: 767px) {
    .defaultColumn {
        display: none!important;
    }
}