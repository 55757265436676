.sm-clean a .sub-arrow {
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: auto;
	right: 4px;
	width: 28px;
	height: 28px;
	overflow: hidden;
	font: bold 22px/28px monospace !important;
	text-align: center;
	text-shadow: none;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
}
.sm-clean a .sub-arrow::before {
	content: '+';
}
.sm-clean a.highlighted .sub-arrow::before {
	content: '-';
}
.sm {
	box-sizing: border-box;
	position: relative;
	z-index: 9999;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.sm, .sm ul, .sm li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: normal;
	direction: ltr;
	text-align: left;
}
.sm-rtl, .sm-rtl ul, .sm-rtl li {
	direction: rtl;
	text-align: right;
}
.sm>li>h1, .sm>li>h2, .sm>li>h3, .sm>li>h4, .sm>li>h5, .sm>li>h6 {
	margin: 0;
	padding: 0;
}
.sm ul {
	display: none;
}
.sm li, .sm a {
	position: relative;
}
.sm a {
	display: block;
}
.sm a.disabled {
	cursor: default;
}
.sm::after {
	content: "";
	display: block;
	height: 0;
	font: 0px/0 serif;
	clear: both;
	overflow: hidden;
}
.sm *, .sm *::before, .sm *::after {
	box-sizing: inherit;
}

.how-works {
	color: white!important;
}
