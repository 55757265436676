.d-page {
  font-size: 20px;
  font-family: 'poppins-semibold';
}

.job-post textarea {
    width: 100%;
    height: 200px;
    padding: 15px;
    margin-bottom: 35px;
}

.btn-submit {
  background: #ff5301;
  color: #fff;
  font-family: "Sui Generis";
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
}
            
.btn-submit:hover {
  color: white!important;
}

.star {
  color: red;
}

.marginBottom-10 {
  margin-bottom: 10px;
}