
.text-white .display-1, .text-white .display-2, .text-white .display-3, .text-white .display-4, .text-white .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6, .text-white a:not(.btn), .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 {
    color: #fff;
}

.footeravatar {
    width: 13%;
    min-width: 90px;
    margin-top: 100px;
    > img {
        max-width: 100%;
        height: auto;
    }
}

 .footer{

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a {
                text-decoration: none;
                position: relative;
                display: block;
                color: #66799e;

                &:hover {
                    color: #ff5301;
                }
            }

            .btn {
                margin: 0;
            }
        }

        &.links-horizontal{
            &:first-child a{
                padding-left: 0;
            }

            &:last-child a{
                padding-right: 0;
            }

        }

        &.links-vertical{
            li{
                display: block;
                margin-left: -5px;
                margin-right: -5px;

                a{
                    padding: 5px;
                    transition: all 0.3s;

                    &:hover{
                        color: #ff5301 !important;
                    }
                }
            }
        }

        &.icon-box{
            i{
                line-height: 1.7
            }
        }
    }

    // .social-buttons{
    //     a,
    //     .btn{
    //         margin-top: 5px;
    //         margin-bottom: 5px;
    //         padding: $padding-footer-sm $padding-footer;
    //     }
    // }

    // .footer-brand{
    //     font-size: $font-size-lg;
    //     font-weight: $font-weight-bold;
        
    //     img.common {
    //         height: 40px;
    //     }
    
    //     img {
    //         height: 40px;
    //         width: 40px;
    //     }
    //     &:hover,
    //     &:focus{
    //         color: #000;
    //     }
    // }
    .footer-language-link{
        font-size:.875rem;
        i{
            font-size: 0.75rem;
        }
    }
    .copyright{
        font-size: .875rem
    }

    .pull-center{
        display: inline-block;
        float: none;
    }

    &-line {
        width: 42%;
        margin-top: 9%;
        // padding-top: 45px;

        @media only screen and (max-width: 770px) {
            margin-top: 12%;
          }

    }
}